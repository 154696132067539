<template>
	<div
		class="flex h-6 w-full items-center justify-between gap-2 px-3 text-[0.5rem] text-white md:justify-center md:gap-4 md:text-xs"
		:style="{ 'background-color': ThemeStore?.settings?.headerAccentColor ?? '#000000' }"
	>
		<div class="flex items-center justify-center">
			<IconsPin class="h-3 w-3 fill-primary text-primary md:h-4 md:w-4" />
			<span class="fill-primary text-primary">Monroe, MI, 1118 S Telegraph Rd 48161</span>
		</div>
		<div>
			<span>Mon-Sun 9-9</span>
		</div>
		<div class="flex items-center justify-center">
			<IconsPhone class="h-3 w-3 fill-white md:h-4 md:w-4" />
			<NuxtLink
				to="tel:8777933397"
				no-prefetch
				class="text-white focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
			>
				(877) 793-3397
			</NuxtLink>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useThemeStore } from '../../stores/themeStore';
const ThemeStore = useThemeStore();
</script>
