<template>
	<SlideOutWrapper :is-open="CartStore.cartOpen" @close="CartStore.cartOpen = false">
		<button
			:tabindex="0"
			:class="[
				'float-right mt-1 px-1 py-0.5 text-sm text-zinc-600 transition-colors duration-200 ease-in-out hover:text-zinc-800',
				'focus-visible:rounded-sm focus-visible:outline focus-visible:outline-cta',
			]"
			@click="CartStore.clearCart()"
		>
			Clear cart
		</button>

		<p class="mb-5 flex w-full justify-center">{{ CartStore.items.length }} items</p>
		<CartItem v-for="item in CartStore.items" :key="item.id" :item="item" />
		<CartPercentageBar />
		<div
			v-if="CartStore.totalDiscount.totalDiscountDollarAmount > 0"
			class="mb-3 text-right text-sm"
		>
			<p>Subtotal: ${{ CartStore.subTotal.toFixed(2) }}</p>
			<p class="font-semibold">
				Mix & Match discounts: ${{
					CartStore.totalDiscount.totalDiscountDollarAmount.toFixed(2)
				}}
			</p>
		</div>

		<div class="flex w-full flex-col items-center">
			<button
				:disabled="!CartStore.items.length"
				:class="[
					'flex h-16 w-full items-center justify-center gap-4 rounded-full bg-cta text-lg font-bold text-white md:text-xl',
					'transition duration-200 ease-in-out hover:bg-cta-hover',
					'focus-visible:ring focus-visible:ring-primary',
					!CartStore.items.length ? '!cursor-not-allowed opacity-25 grayscale' : '',
				]"
				@click="
					{
						CartStore.merchCrossSellOpen = true;
						CartStore.cartOpen = false;
						gtmCheckoutInitiated();
					}
				"
			>
				Checkout ${{ CartStore.total.toFixed(2) }}
				<ArrowRightIcon class="-mr-4 w-8 stroke-2" />
			</button>

			<NuxtLink
				to="/return-policy"
				no-prefetch
				class="-mb-2 mt-2 p-1 text-xs italic text-zinc-600 underline focus-visible:outline focus-visible:outline-1 focus-visible:outline-cta"
				@click="CartStore.cartOpen = false"
				>Return Policy</NuxtLink
			>

			<NuxtLink
				to="/#shop"
				no-prefetch
				:class="[
					'mt-4 flex w-full justify-center rounded-full bg-zinc-900 py-1 text-white',
					'transition duration-200 ease-in-out hover:bg-zinc-950',
					'focus-visible:ring focus-visible:ring-primary',
				]"
				@click="CartStore.cartOpen = false"
				>Continue Shopping</NuxtLink
			>
		</div>

		<CartNotices />
	</SlideOutWrapper>

	<!-- Merch cross sell modal -->
	<Teleport to="body">
		<Modal
			:modal-open="CartStore.merchCrossSellOpen"
			@modal-close="CartStore.merchCrossSellOpen = false"
		>
			<CartCrossSells />
		</Modal>
	</Teleport>
</template>

<script setup lang="ts">
import { useCartStore } from '../stores/cartStore';
import CartItem from './cart/CartItem.vue';
import CartNotices from './cart/CartNotices.vue';
import SlideOutWrapper from './ui/SlideOutWrapper.vue';
import Modal from './ui/Modal.vue';
import CartCrossSells from './cart/CartCrossSells.vue';
import ArrowRightIcon from '@heroicons/vue/24/outline/ArrowRightIcon';
import handleFrontendError from '../utils/handleFrontendError';
import { useGtm } from '@gtm-support/vue-gtm';

const CartStore = useCartStore();

const event = computed(() => {
	if (useRoute().path === '/quick-order') return 'quick-order-begin-checkout';
	if (useRoute().path === '/build-a-pack') return 'bundle-builder-begin-checkout';
	return 'begin-checkout';
});

function gtmCheckoutInitiated() {
	try {
		useGtm()?.trackEvent({
			event: event.value,
			category: 'Cart',
			action: 'Proceed to Checkout',
			value: +CartStore.total.toFixed(2),
			noninteraction: false,
		});
	} catch (error) {
		handleFrontendError(error, false);
	}
}
</script>
